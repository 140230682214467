<template>
  <app-page name="Настройки тизеров">
    <div
      class="teasers-settings__message"
      ref="message"
      :style="{ opacity: isMessageShown ? 1 : 0 }"
    >
      Обновленно!
    </div>
    <div class="teasers-settings__updating" v-if="loading && errors">
      <div class="teasers-settings__updating-errors" v-if="errors">
        <span v-if="errors">{{ errors.title }}</span>
        <Button
          text="Ок"
          className="btn-secondary"
          class="_filled"
          @click="clear"
        />
      </div>
      <span v-else>Updating...</span>
    </div>
    <div class="teasers-settings__content">
      <h1 class="page-title">Настройки тизеров</h1>
      <div class="teasers-settings__body">
        <p>
          Настройки на данный момент: Дни: {{ actual_days }}, клики:
          {{ actual_clicks }}
        </p>
        <div class="teasers-settings__body-top">
          <TextInput
            label="Кликов для апдейта веса"
            v-model:value="settings.clicks"
            placeholder="Кол-во кликов"
          />
          <TextInput
            label="Дней для апдейта веса"
            v-model:value="settings.days"
            placeholder="Кол-во дней"
          />
          <Button
            text="Применить"
            className="btn-secondary"
            class="_filled"
            @click="saveSettings"
          />
        </div>
        <label class="teasers-settings__upload">
          Загрузить обновления веса
          <input type="file" @change="readSingleFile" ref="teasers_file" />
        </label>
      </div>
    </div>
  </app-page>
</template>

<script>
import AppPage from "@/components/AppPage";
import Button from "@/components/Button";
import TextInput from "@/components/TextInput";
import axios from "axios";

export default {
  components: {
    AppPage,
    Button,
    TextInput,
  },
  data() {
    return {
      loading: false,
      errors: null,
      isMessageShown: false,
      actual_clicks: null,
      actual_days: null,
      settings: {
        clicks: "",
        days: "",
      },
    };
  },
  created() {
    this.getSettings();
  },
  methods: {
    load() {
      this.getSettings();
      this.clear();
    },
    clear() {
      this.$refs.teasers_file.value = "";
      this.loading = false;
      this.errors = null;
    },
    async getSettings() {
      const { data } = await axios.get("/api/teasers/get-settings");
      this.actual_days = data.days;
      this.actual_clicks = data.clicks;
    },
    async readSingleFile(evt) {
      let f = evt.target.files[0];
      this.loading = true;
      this.errors = null;
      let contents;
      if (f.name.toLowerCase().lastIndexOf(".csv") == -1) {
        alert("Please upload only CSV files");
        return;
      }
      if (f) {
        let r = new FileReader();
        r.onload = function (e) {
          contents = e.target?.result;
        };
        r.readAsText(f);

        let formData = new FormData();
        formData.append("teasers", f);
        try {
          await axios.post("/api/teasers/update", formData);
          this.$refs.teasers_file.value = "";
          this.loading = false;
          this.isMessageShown = true;
          setTimeout(() => {
            this.isMessageShown = false;
          }, 3300);
        } catch (e) {
          if (e.response.status === 500) {
            this.errors = e.response.data.errors;
          }
        }
      }
    },
    async saveSettings() {
      try {
        await axios.post("/api/teasers/save-settings", {
          clicks: parseInt(this.settings.clicks),
          days: parseInt(this.settings.days),
        });
        this.settings.clicks = "";
        this.settings.days = "";
        this.load();
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.teasers-settings {
  > p {
    font-size: 14px;
  }

  &__body {
    margin-top: 20px;

    &-top {
      margin-top: 20px;
      display: flex;
      gap: 30px;
      flex-wrap: wrap;

      > button {
        margin-top: 24px;
      }
    }
  }

  &__upload {
    display: block;
    margin-top: 20px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    height: 48px;
    padding: 8px 16px;
    background: #55d740;
    border: 1px solid transparent;
    color: #fff;
    width: max-content;

    input {
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  &__message {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 10;
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 4px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &__updating {
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    &-errors {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
      justify-content: center;

      button {
        font-size: 14px;
        width: 150px;
      }
    }
  }
}
</style>
